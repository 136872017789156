<template>
  <div class="grid md:grid-cols-5 grid-cols-2 gap-4 p-4">
    <logic-card v-for="(logic, idx) of logics" :key="idx" :logic="logic" />
  </div>
</template>

<script>
import LogicCard from '@/components/apps/logics/logic-card/index.vue'

export default {
  name: 'preset',

  meta: {
    title: 'Preset ranking',
  },

  components: {
    LogicCard,
  },

  data: () => ({
    logics: [],
  }),

  async mounted() {
    this.logics = await this.$http.get('/api/logics/public')
  },
}
</script>
