<template>
  <div>
    <div class="font-medium text-lg">{{ $t('Copy Logic') }}</div>

    <div class="form-control" v-for="config in configs" :key="config.key">
      <label class="label" :for="config.key">
        <span
          class="label-text font-semibold text-gray-500"
          v-text="config.label"
        />
      </label>

      <select
        v-if="config.type === 'SELECT'"
        :id="config.key"
        class="select select-bordered w-full border-solid"
        :class="error && !input[config.key] ? 'select-error' : ''"
        v-model="input[config.key]"
      >
        <option disabled="disabled" selected="selected">
          {{ $t('Please select account') }}
        </option>
        <option
          v-for="{ label, value } in config.options"
          :key="value"
          :value="value"
        >
          {{ label }}
        </option>
      </select>
      <input
        v-else
        :id="config.key"
        type="text"
        class="input-box"
        :class="error && !input[config.key] ? 'input-box-error' : ''"
        v-model.trim="input[config.key]"
      />
    </div>

    <label for="total" class="label">
      <span class="label-text font-semibold text-gray-500">
        {{ $t('Total') }}
      </span>
    </label>
    <input id="total" type="number" class="input-box" v-model="total" />

    <div v-if="logic.memo" class="mt-4">
      <label class="label">
        <span class="label-text font-semibold text-gray-500">Description</span>
      </label>
      <div class="h-60 overflow-y-auto border p-2 rounded-md">
        <div v-html="logic.memo" class="prose-sm" />
      </div>
    </div>

    <div class="modal-action">
      <button-group class="space-x-2" :buttons="buttons" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'clone-logic',

  props: {
    logic: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    error: false,
    total: 1,
    input: {
      logic: null,
      credential: null,
      name: '',
    },
  }),

  computed: {
    ...mapGetters({
      accountOptions: 'credentials/validAccountOptions',
    }),
    configs() {
      return [
        {
          key: 'credential',
          type: 'SELECT',
          label: this.$t('Account'),
          options: this.accountOptions,
        },
        {
          key: 'name',
          label: this.$t('Bot Name'),
        },
      ]
    },
    buttons() {
      return [
        {
          class: 'btn-sm',
          label: this.$t('Close'),
          handler: (event) => {
            this.$$closeModal(event)
          },
        },
        {
          class: 'btn-sm-warning',
          label: this.$t('Clone'),
          handler: this.clone,
        },
      ]
    },
  },

  mounted() {
    this.input.logic = this.logic._id
    this.input.name = this.logic.name
    if (this.accountOptions[0]?.value) {
      this.input.credential = this.accountOptions[0]?.value
    }
  },

  methods: {
    async clone(event) {
      this.error = false
      if (!this.input.credential || !this.input.name) {
        this.error = true
      } else {
        await this.$http.post('/api/logics/clone', {
          ...this.input,
          total: parseInt(this.total),
        })
        this.$router.push('/my-bots')
        this.$$closeModal(event)
      }
    },
  },
}
</script>
