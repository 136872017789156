<template>
  <div
    :class="bgClass"
    class="card shadow-lg compact side cursor-pointer"
    @click.stop="copy(logic)"
  >
    <div class="flex-row items-center space-x-4 card-body relative">
      <div
        class="absolute top-0 right-1 badge badge-error font-bold"
        v-if="isNew"
      >
        NEW
      </div>
      <div>
        <div a="avatar">
          <div class="rounded-full w-10 h-10 shadow flex-center">
            <img src="/img/bot.svg" />
          </div>
        </div>
      </div>
      <div class="card-title truncate">
        {{ logic.name }}
      </div>
    </div>
  </div>
</template>

<script>
import ClonePreset from '@/components/apps/logics/clone-logic/index.vue'

const disableCodes = [
  '74-two-step-logic',
  '62-cryptoquant-netflow',
  '65-iv-band-signal',
  '71-iv-band-signal-2',
  '72-iv-band-signal-II',
  '73-iv-band-signal-III',
]

export default {
  name: 'logic-card',

  props: {
    logic: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isNew() {
      if (!this.logic.createdAt) {
        return false
      }
      const createdAt = new Date(this.logic.createdAt).getTime()
      if (createdAt < 1702353145588) {
        return false
      }
      return Date.now() - createdAt < 2592000000
    },
    disable() {
      return disableCodes.includes(this.logic.code)
    },
    bgClass() {
      return this.disable
        ? 'hidden bg-gray-400 cursor-not-allowed'
        : 'bg-base-100'
    },
  },

  methods: {
    copy(logic) {
      if (this.disable) {
        return
      }
      const props = { logic }
      this.$$showModal({
        component: ClonePreset,
        props,
      })
    },
  },
}
</script>
